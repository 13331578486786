import React, { useEffect, useState, useRef } from 'react';
import { useQuery, gql } from "@apollo/client";
import { BrowserRouter, Route } from 'react-router-dom';
import Error404Page from '../../pages-other/NewSite404/newSite404';

//Get page header based on ID
const pageQuery = gql`
  query pageHeaderQuery($slug: [String]) {
    entries(slug: $slug) {
      title
      ... on team_team_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on pages_default_Entry {
        blurb
        featuredImage {
          url
        }
      }
      ... on newsArchive_newsArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on storiesArchive_storiesArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on pressArchive_pressArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on resourcesArchive_resourcesArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on researchArchive_researchArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on programsArchive_programsArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on projectArchives_projectArchives_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on opportunitiesArchive_opportunitiesArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on newslettersArchive_newslettersArchive_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on equityCommitment_equityCommitment_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on research_research_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
    }
  }
`;

// WORKING

function Hero({ slug }) {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: { slug },
  });

  const [bgImage, setBgImage] = useState(null);
  const [title, setTitle] = useState('');
  const [blurb, setBlurb] = useState('');
  const [isInView, setIsInView] = useState(false);
  
  const heroRef = useRef(null);

  // IntersectionObserver to detect when the hero section comes into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting); // Update state when in view
      },
      { threshold: 0.1 } // Trigger when 10% of the element is in view
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  // Set state when page data is loaded
  useEffect(() => {
    if (!loading && !error && data?.entries?.length > 0) {
      const pageHeader = data.entries[0];
      setBlurb(pageHeader.blurb || '');
      setTitle(pageHeader.customTitle || pageHeader.title || '');

      if (pageHeader.featuredImage && pageHeader.featuredImage.length > 0) {
        setBgImage(pageHeader.featuredImage[0]);
      }
    }
  }, [data, loading, error]);

  const myStyle = isInView && bgImage
    ? {
        backgroundImage: `url(${bgImage.url})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }
    : {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };

  const bg = bgImage ? 'bgImage' : 'orange';
  const withBlurb = blurb ? 'withBlurb' : '';
  const classes = `${bg} ${withBlurb}`;

  return (
    <div id="heroContent" className={classes} ref={heroRef}>
      <div className="mainHeroContent" style={myStyle}>
        {/* Skeleton loader while data is loading */}
        {loading ? (
          <div className="skeleton-loader"></div>
        ) : (
          <h1>{title}</h1>
        )}
      </div>
      {blurb && !loading && (
        <div className="blurb">
          <div className="container">
            <div dangerouslySetInnerHTML={{ __html: blurb }}></div>
          </div>
        </div>
      )}
    </div>
  );
}

function PageHeader({ slug }) {
  const { data, loading, error } = useQuery(pageQuery, {
    variables: { slug },
  });

  if (loading) return null;

  return (
    <section id="hero">
      <Hero slug={slug} />
    </section>
  );
}

export default PageHeader;