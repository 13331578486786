import React, { useState } from 'react';
import './homeRedesign2023Header.css';

const HomeRedesign2023Header = () => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const heroImageUrl = "https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/New-Homepage_Hero-Image.jpg";

  return (
    <div id="homeRedesign2023-header-container">
      {/* Skeleton Loader (Only shows while loading) */}
      {!imageLoaded && <div className="skeleton-loader"></div>}

      {/* Hero Image Container with Lazy Loading */}
      <div
        className={`homeRedesign2023-header-image-container ${imageLoaded ? "visible" : "hidden"}`}
        style={{ backgroundImage: imageLoaded ? `url(${heroImageUrl})` : "none" }}
      >
        <img
          srcSet={`${heroImageUrl}?w=1200 1200w, ${heroImageUrl}?w=800 800w, ${heroImageUrl}?w=400 400w`}
          sizes="(max-width: 1150px) 800px, 1200px"
          src={`${heroImageUrl}?w=1200`}  // Fallback for browsers that don't support srcset
          alt="Education research organization"
          className="lazy-loaded-image"
          loading="lazy"
          onLoad={() => setImageLoaded(true)}
        />
      </div>

      <div className="homeRedesign2023-header-text-container">
        <h1>
          We are an education research organization with a philanthropic focus on{" "}
          <span className="homeRedesign2023-header-emphasized-text">
            expanding ideas about learning and creating direct value for youth
          </span>.
        </h1>
        <a href="/what-we-do">Learn More →</a>
      </div>
    </div>
  );
};

export default HomeRedesign2023Header;