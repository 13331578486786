import React, { useEffect, useState, useRef, Component } from "react";
import { useQuery, gql } from "@apollo/client";
import { withRouter } from "react-router-dom";

// Get page header based on ID
const pageQuery = gql`
  query pageHeaderQuery($slug: [String]) {
    entries(slug: $slug) {
      title
      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          url
        }
      }
    }
  }
`;

const Hero = withRouter(({ slug, history }) => {
  const { data, loading, error } = useQuery(pageQuery, { variables: { slug } });
  const [bgImage, setBgImage] = useState(null);
  const [title, setTitle] = useState("");
  const [blurb, setBlurb] = useState("");
  // const [isInView, setIsInView] = useState(false);

  const heroRef = useRef(null);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => setIsInView(entry.isIntersecting),
  //     { threshold: 0.1 }
  //   );

  //   if (heroRef.current) observer.observe(heroRef.current);

  //   return () => {
  //     if (heroRef.current) observer.unobserve(heroRef.current);
  //   };
  // }, []);

  useEffect(() => {
    if (!loading && !error && data?.entries?.length > 0) {
      const pageHeader = data.entries[0];
      setBlurb(pageHeader.blurb || "");
      setTitle(pageHeader.customTitle || pageHeader.title || "");

      if (pageHeader.featuredImage?.length > 0 && pageHeader.featuredImage[0]?.url) {
        setBgImage(pageHeader.featuredImage[0].url);
      }
    }
  }, [data, loading, error]);

  if (loading) {
    return <div className="skeleton-loader"></div>;
  }

  if (error) return `Error! ${error.message}`;
  if (!data.entries || data.entries.length === 0) {
    history.push("/not-found");
    return null;
  }

  const myStyle = bgImage
    ? { backgroundImage: `url(${bgImage})`, backgroundSize: "cover", backgroundRepeat: "no-repeat" }
    : { backgroundSize: "cover", backgroundRepeat: "no-repeat" };

  return (
    <div id="heroContent" className={bgImage ? "bgImage withBlurb" : "orange"} ref={heroRef} style={myStyle}>
      <Blurb slug={title} position={bgImage ? "absolute" : "relative"} />
    </div>
  );
});

function Blurb({ slug, position = "absolute" }) {
  return slug ? (
    <div className={`blurb ${position}`}>
      <h1 className="text-large">{slug}</h1>
    </div>
  ) : null;
}

export default class ResourceHeader extends Component {
  render() {
    return (
      <section id="hero">
        <Hero slug={this.props.slug} />
        <div className="container">
          {this.props.blurb && <Blurb slug={this.props.slug} />}
        </div>
      </section>
    );
  }
}
