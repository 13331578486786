import React, { useState, useEffect } from "react";
import './PhilanthropyLandingPage-Header.css';

// Skeleton Loader
const SkeletonLoader = () => {
  return <div className="philanthropy-skeleton-loader"></div>;
};

// Header Component
const HeaderComponent = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // This could be triggered by your actual image or data loading process
    const img = new Image();
    img.src = "https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Collaborative+Philanthropy/Philanthropy+Header+Final.png";
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div id="philanthropyLandingPage-header-container">
      {!imageLoaded ? (
        <SkeletonLoader />
      ) : (
        <img src="https://f10-image.s3-us-west-2.amazonaws.com/New+Site/Collaborative+Philanthropy/Philanthropy+Header+Final.png" alt="Header" loading="lazy" 
          style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "-1",
        }}
        />
      )}
      <div class="philanthropyLandingPage-header-content-container">
        <h1>Philanthropy</h1>
      </div>
    </div>
  );
};

export default HeaderComponent;
