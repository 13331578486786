import React, { Component } from 'react';
import {
  useQuery,
  gql
} from "@apollo/client";
import {InlineReactionButtons} from 'sharethis-reactjs';
import {InlineShareButtons} from 'sharethis-reactjs';
import {StickyShareButtons} from 'sharethis-reactjs';
import {InlineFollowButtons} from 'sharethis-reactjs';
import Breadcrumbs from '../components/breadcrumbs.js';
import ContentBlocks from '../components/ContentBlocks';
// import GlobalFooter from '../components/base/GlobalFooter';
import NewFooter from '../../pages-other/NewFooter.js';
import Grid from '@material-ui/core/Grid';
import StoryCard from '../components/storyCard';
// import ShareThis from "../components/Share-this"
import Seomatic from '../components/base/seomatic.js';

const segment_str = window.location.pathname; // return segment1/segment2/segment3/segment4
const segment_array = segment_str.split( '/' );
const currentUrl=window.location.href.split('/')
const pathName=currentUrl
const lastItem = segment_array[segment_array.length - 1];
const last_segment = segment_array.pop();

//Set Breadcrumb values
const params = [
  { parent: segment_array[1]},
  { pageTitle: last_segment},
  { style: '' }
]

// Get the preview token from the URL
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';

// Then forward that on whenever you are sending an API request
let url = `...?token=${token}`;

const QUERY = gql`
  query GetStories($slug: [String]) {
    entry(slug: $slug) {
      sectionHandle
      title
      postDate @formatDateTime(format: "d M Y")
      postDateAlt: postDate @formatDateTime(format: "F j, Y")
      author {
        fullName
      }
      url
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        categories {
          id
          title
          level
        }
        themes {
          id
          title
          level
        }
        interestAreas {
          title
          id
        }
        audiences {
          title
          id
        }
        seomatic {
          metaLinkContainer
          metaTagContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
            researchExternalAuthorsPosition
            researchExternalAuthorsBio
            researchExternalAuthorsImage {
              url
            }
            researchExternalAuthorsLink {
              url
              customText
            }
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              slug
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          } 
          ... on contentOptions_featuredResource_BlockType {
            featuredResource {
              title 
              slug
              ... on resources_default_Entry {
                thumbnailImage {
                  url
                }
                blurb
                title
                featuredImageCaption
                researchAsset {
                  url
                }
              }
            }
          }

          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_linkList_BlockType {
            linkListTitle
            linkListList {
              __typename  
              ... on linkListList_BlockType {
                linkListTitle
                linkUrl
                linkListBg {
                  url
                }
                linkListHoverTxt
                linkListTitle
              }
            }
            linkListDisplay
            linkListSize
            columnCount
          }
          ... on contentOptions_video_BlockType {
            videoTitle
            videoLink
            videoCaption
            videoImage {
              url
            }
          }
          ... on contentOptions_thirdPartyCode_BlockType {
            thirdPartyCodeTitle
            thirdPartyCodeCode
          }
          ... on contentOptions_list_BlockType {
            list {
              ... on list_BlockType {
                id
                listItemRichContent
                listItemImage {
                  url
                }
                listItemHeadline
                listItemLink {
                  url
                  customText
                  target
                }
              }
            }
            listTitle
          }
          ... on contentOptions_slider_BlockType {
            id
            sliderTitle
            slides {
              ... on slides_BlockType {
                id
                slideImage {
                  url
                }
                slideHeadline
                slideContent
                slideLink {
                  customText
                  url
                }
              }
            }
          }
          ... on contentOptions_featuredStory_BlockType {
            id
            featuredstory {
              ... on stories_default_Entry {
                id
                slug
                categories {
                  title
                  id
                }
                author {
                  fullName
                }
                postDate @formatDateTime(format: "F j, Y")
                researchExternalAuthors {
                  ... on researchExternalAuthors_BlockType {
                    id
                    researchExternalAuthorsAuthor
                  }
                }
                interestAreaTeam {
                  ... on interestAreaTeam_BlockType {
                    id
                    interestAreaTeamMember {
                      title
                    }
                  }
                }
                featuredImage {
                  url
                }
                blurb
                title
              }
              dateCreated @formatDateTime(format: "F j, Y")
            }
          }
          ... on contentOptions_featuredPerson_BlockType {
            id
            featuredPerson {
              ... on teamMembers_default_Entry {
                id
                teamMemberImage {
                  url
                }
                teamMembersPronouns
                teamMemberPosition
                teamMemberBio
              }
              title
              url
            }
          }
          ... on contentOptions_table_BlockType {
            id
            table {
              col1
              col2
              col3
            }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
      }
      ... on resources_default_Entry {
        customTitle
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
      }
      ... on programs_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
      }

      ... on opportunities_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
      }
      ... on projects_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
              ... on teamMembers_default_Entry {
                teamMemberPosition
                teamMembersPronouns
                teamMemberImage {
                  url
                }
                teamMemberBio
                teamMemberEmail
              }
            }
          }
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
            emphasizedTitle
            emphasizedSubheadline
            emphasizedOverline
            emphasizedImage {
                url
            }
            emphasizedImageCaption
            emphasizedContent
            emphasizedLink
            emphasizedBackgroundColor
          }
          ... on contentOptions_quote_BlockType {
            pullQuote
            quoteSocial
            quoteImage {
              url
            }
            quoteAttribution
          }
          ... on contentOptions_quoteSlideshow_BlockType {
            quoteSliderTitle
            quotes {
              __typename  
              ... on quotes_BlockType {
                quotesQuote
                quotesQuoteImage {
                  url
                }
                quotesQuoteAttribution
              }
            }
          }
          ... on contentOptions_accordion_BlockType {
            accordionTitle
            accordion {
              __typename  
              ... on accordion_BlockType {
                accordionItemTitle
                accordionItemBlurb
                accordionItemImage {
                  url
                }
                accordionItemIcon {
                  url
                }
                accordionItemContent
              }
            }
          }
          ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
          }
          ... on contentOptions_insightsAndUpdates_BlockType {
            insightsAndUpdates {
              title
              slug
              ... on resources_default_Entry {
                featuredImage {
                  url
                }
                title
                resourceType {
                  title
                }
                slug
                postDate @formatDateTime(format: "F j, Y")
              }
              ... on stories_default_Entry {
                title
                featuredImage {
                  url
                }
                categories {
                  title
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on press_default_Entry {
                title
                pressType {
                  title
                }
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                url
              }
              ... on programs_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
              }
              ... on projects_default_Entry {
                title
                featuredImage {
                  url
                }
                postDate @formatDateTime(format: "F j, Y")
                slug
                projectType {
                  title
                }
              }
            }
          }
          ... on contentOptions_newsletterSignup_BlockType {
            id
          }
        }
      }
      ... on press_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        featuredImageCaption
        themes {
          title
          id
        }
        pressType {
          title
          id
        }
        interestAreas {
          title
          id     
        }
        audiences {
          title
          id
        }
        contentOptions {
          ... on contentOptions_standardContent_BlockType {
            contentTitle
            contentContent
            linkUrl {
              url
              customText
              target
            }
            contentAlignment
          }
          ... on contentOptions_icp_BlockType {
            icpTitle
            icpContent
              icpImage {
                  url
              }
              icpImageCaption
              icpPositionOfImage
              icpBackgroundColor
          }
          ... on contentOptions_fwi_BlockType {
            fwiTitle
            fwiImage {
                url
            }
            fwiCaption
            fwiSize
          }
          ... on contentOptions_emphasized_BlockType {
                emphasizedTitle
                emphasizedSubheadline
                emphasizedOverline
                emphasizedImage {
                    url
                }
                emphasizedImageCaption
                emphasizedContent
                emphasizedLink
                emphasizedBackgroundColor
            }
            ... on contentOptions_quote_BlockType {
                pullQuote
                quoteSocial
                quoteImage {
                  url
                }
                quoteAttribution
            }
            ... on contentOptions_quoteSlideshow_BlockType {
              quoteSliderTitle
              quotes {
                __typename  
                ... on quotes_BlockType {
                  quotesQuote
                  quotesQuoteImage {
                    url
                  }
                  quotesQuoteAttribution
                }
              }
            }
            ... on contentOptions_accordion_BlockType {
              accordionTitle
              accordion {
                __typename  
                ... on accordion_BlockType {
                  accordionItemTitle
                  accordionItemBlurb
                  accordionItemImage {
                    url
                  }
                  accordionItemIcon {
                    url
                  }
                  accordionItemContent
                }
              }
            }
            ... on contentOptions_linkList_BlockType {
              linkListTitle
              linkListList {
                __typename  
                ... on linkListList_BlockType {
                  linkListTitle
                  linkUrl
                  linkListBg {
                    url
                  }
                  linkListHoverTxt
                  linkListTitle
                }
              }
              linkListDisplay
              linkListSize
              columnCount
            }
            ... on contentOptions_video_BlockType {
              videoTitle
              videoLink
              videoCaption
              videoImage {
                url
              }
            }
            ... on contentOptions_insightsAndUpdates_BlockType {
              insightsAndUpdates {
                title
                slug
                ... on resources_default_Entry {
                  featuredImage {
                    url
                  }
                  title
                  resourceType {
                    title
                  }
                  slug
                  postDate @formatDateTime(format: "F j, Y")
                }
                ... on stories_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  categories {
                    title
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on press_default_Entry {
                  title
                  pressType {
                    title
                  }
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  url
                }
                ... on programs_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                }
                ... on projects_default_Entry {
                  title
                  featuredImage {
                    url
                  }
                  postDate @formatDateTime(format: "F j, Y")
                  slug
                  projectType {
                    title
                  }
                }
              }
            }
            ... on contentOptions_newsletterSignup_BlockType {
              id
            }
        }
      }
    }
  }
`

const ThemeQuery = gql`
  query GetThemesOfStory($slug: [String]) {
    entry(slug: $slug) {
      ... on stories_default_Entry {
        themes {
          id
          title
          level
        }
      }
    }
  }
`

const RelatedPostsQuery = gql`
  query GetRelatedStories($channel: [String], $cat: [QueryArgument]) {
    entries(section: $channel, relatedToCategories: [{ id: $cat }]) {
      title
      slug
      url
      author {
        fullName
      }
      postDate @formatDateTime (format: "M j, Y")
      ... on stories_default_Entry {
        blurb
        featuredImage {
          title
          url
        }
        researchExternalAuthors {
          ... on researchExternalAuthors_BlockType {
            researchExternalAuthorsAuthor
          }
        }
        interestAreaTeam {
          ... on interestAreaTeam_BlockType {
            interestAreaTeamMember {
              title
            }
          }
        }
        categories {
          title
          id
        }
      }
    }
  }
`
const RelatedPosts = (slug) => { 
  const { data:themeData } = useQuery(ThemeQuery, {
    variables: {slug}
  });

  let theme_category_ids = [];
  if (themeData) {
    themeData.entry.themes.map((theme) => {
      if (theme.level > 1) {
        theme_category_ids.push(theme.id);
      }
    })
  }

  const { data, loading, error } = useQuery(RelatedPostsQuery, {
    variables: {
      channel: 'stories',
      // cat: pathName[4] == 'press' ? 691:690
      cat: theme_category_ids
    }
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }
  if (theme_category_ids.length == 0 || data.entries.length == 1) {
    return <div></div>
  }
  let block_count = 0;
  return (
    <div className='relatedPosts'>
      <div className='container'>
        <h2 className='text-large'>Explore Related Stories</h2>
        <Grid container spacing={4}>
          {data.entries.map(
            (block, index) => {
              if (block.slug != slug.slug && block_count < 3) {
                let props = {
                  block: {block}
                }
                block_count += 1;
                return (
                  <Grid key={index} item sm={12} md={4}>
                    <StoryCard {...props} />
                  </Grid>
                )
              }
            }
          )}
        </Grid>
        <a className="button" href="/stories">Back to All Stories  <i className="fas fa-arrow-right" aria-hidden="true"></i></a>
      </div>
    </div>
  );
}

const InlineContent = (slug) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {slug}
  });
  if (loading) { return <div>loading</div> }
  if (error) { return <div>{error}</div> }

  let author = '';
  let internalAuthors = '';
  let externalAuthors = '';

  if(data.entry.researchExternalAuthors && data.entry.researchExternalAuthors.length > 0) {
    externalAuthors = data.entry.researchExternalAuthors.map(item => item.researchExternalAuthorsAuthor).join(', ');
  }
  if (data.entry.interestAreaTeam && data.entry.interestAreaTeam.length > 0) {
    internalAuthors = data.entry.interestAreaTeam[0].interestAreaTeamMember.map(item => item.title).join(', ');
  }
 
  //Join Authors
  if(externalAuthors != '' && internalAuthors != '') {
    author = internalAuthors+ ', '+ externalAuthors
  } else if (externalAuthors == '' && internalAuthors != '') {
    author = internalAuthors
  } else if (externalAuthors != '' && internalAuthors == '') {
    author = externalAuthors
  }
  // params = [{ parent: data.entry.sectionHandle }, { pageTitle: data.entry.sectionHandle }, { style: 'dark' }];
  return (
    <div className='entryContent'>
      <div className='container'>
        {data.entry.seomatic && (
          <Seomatic data={data.entry.seomatic} />
        )}
        {data.entry.categories && (
          <div className='catLabel strong'>{data.entry.categories[0].title}</div>
        )}
        {/* {data.entry.pressType && (
          <h4 className='catLabel strong'>{data.entry.pressType[0].title}</h4>
        )} */}
        <h1 className='text-xl_bold'>{data.entry.title}</h1>
        {data.entry.blurb && (
            <div className='entryBlurb'
                dangerouslySetInnerHTML={{
                __html: data.entry.blurb,
                }}
            ></div>
        )}
        <p className="text-left text-body_bold textGray">{author && <span>{author}  / </span> }  {data.entry.postDateAlt}</p>
        {data.entry.featuredImage[0] && (
          <img className='featuredImage' src={data.entry.featuredImage[0].url} alt={data.entry.title} fetchpriority="high" />
        )}
        
        {data.entry.featuredImageCaption && ( 
          <p className='featuredImageCaption text-half-step'>{data.entry.featuredImageCaption}</p>
        )}

        <ContentBlocks data={data.entry} />

        <div className='catTags'>
          {data.entry.themes && (
            data.entry.themes.map((block) => {
              if (block.level > 1) {
                if (data.entry.sectionHandle == 'stories') {
                  return <a href={"/stories?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
                } else {
                  return <a href={"/news/press?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
                }
              }
            })
          )}

          {data.entry.interestAreas && (
            data.entry.interestAreas.map((block) => {
            
                   if(data.entry.sectionHandle == 'stories') {
                return <a href={"/stories?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }else{
                return <a href={"/news/press?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }
            })
          )}
          
          {data.entry.audiences && (
            data.entry.audiences.map((block) => {
              if(data.entry.sectionHandle == 'stories') {
                return <a href={"/stories?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }else{
                return <a href={"/news/press?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }
            })
          )}

          {data.entry.categories && (
            data.entry.categories.map((block) => {
              if(data.entry.sectionHandle == 'stories') {
                return <a href={"/stories?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }else{
                return <a href={"/news/press?cat=" + block.id}><i className="fa-solid fa-tag"></i>{block.title}</a>
              }
            })
          )}
        
          <div className="share">
            <a className="text-half-step_bold st-custom-button" data-network="sharethis"><InlineShareButtons config={{
            alignment: 'center',  // alignment of buttons (left, center, right)
            color: 'social',      // set the color of buttons (social, white)
            // enabled: true,        // show/hide buttons (true, false)
            font_size: 16,        // font size for the buttons
            labels: 'cta',        // button labels (cta, counts, null)
            language: 'en',       // which language to use (see LANGUAGES)
            networks: [           // which networks to include (see SHARING NETWORKS)
              'whatsapp',
              'linkedin',
              'messenger',
              'facebook',
              'twitter'
            ],
            padding: 12,          // padding within buttons (INTEGER)
            radius: 4,            // the corner radius on each button (INTEGER)
            show_total: true,
            size: 40,             // the size of each button (INTEGER)


            // OPTIONAL PARAMETERS

        
          }}>Share This</InlineShareButtons></a>
          </div>
        </div>
        
        {data.entry.researchExternalAuthors && (
          data.entry.researchExternalAuthors.map((block) => {
              return (
                <div className='authorSection'>
                  <div className='authorImage'>
                    <img src={block.researchExternalAuthorsImage[0] ? block.researchExternalAuthorsImage[0].url : '/news-archive-spacer.png' } alt={block.researchExternalAuthorsAuthor} loading='lazy' />
                  </div>
                  <div className='authorContent'>
                    <h5 className='text-small_strong'>About the Author</h5>
                    <h3 className='text-large_bold'>{block.researchExternalAuthorsAuthor}</h3>
                    {block.researchExternalAuthorsPosition && ( 
                      <h4 className='text-half-step'>{block.researchExternalAuthorsPosition}</h4>
                    )}
                    {block.researchExternalAuthorsBio && (
                      <div
                        dangerouslySetInnerHTML={{
                        __html: block.researchExternalAuthorsBio,
                        }}
                      ></div>
                    )}
                    {block.researchExternalAuthorsLink.customText && (
                      <a className='readMore text-small_strong' href={block.researchExternalAuthorsLink.url}>{block.researchExternalAuthorsLink.customText} <i className="fa-solid fa-arrow-right"></i></a>
                    )}
                  </div>
                </div>
              )
          })
        )}

        {data.entry.interestAreaTeam && (
          data.entry.interestAreaTeam[0].interestAreaTeamMember.map((block) => {
              return (
                <div className='authorSection'>
                  <div className='authorImage'>
                    <img src={block.teamMemberImage[0] ? block.teamMemberImage[0].url : '/news-archive-spacer.png' } alt={block.title} loading='lazy' />
                  </div>
                  <div className='authorContent'>
                    <h5 className='text-small_strong'>Author Bio</h5>
                    <h3 className='text-large_bold'>{block.title}</h3>
                    {block.teamMemberPosition && ( 
                      <h4 className='text-half-step'>{block.teamMemberPosition}</h4>
                    )}
                    {block.teamMemberBio && (
                      <div
                        dangerouslySetInnerHTML={{
                        __html: block.teamMemberBio,
                        }}
                      ></div>
                    )}
                    
                     <a className='readMore text-small_strong' href={"/about/team/" + block.slug}>Read More <i className="fa-solid fa-arrow-right"></i></a>
                   
                  </div>
                </div>
              )
          })
        )}
      </div>
    </div>
  );
}

export default class Entry extends Component {
  render() {
    return(
      <div className="pageContainer">
        <Breadcrumbs slug={last_segment} />
        <InlineContent slug={last_segment}/>
        {params[0].parent === "stories" && (
          <RelatedPosts slug={last_segment} />
        )}
        {/* <GlobalFooter /> */}
        <NewFooter />
      </div>
    );
  }
}